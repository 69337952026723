
import FontImporter from "components/FontImporter";
import { useEffect } from "components/lib/react-npm"
import useStylesStore from "store/styles/useStylesStore";


export default function AllStylesProvider({ children }) {
    const { setState, themes, HomePageStyle, authPageStyle } = useStylesStore();
    const fonts = ['Rubik+Glitch+Pop', 'serif', 'Noto+Sans+JP', 'Playwrite+HU', 'Roboto', 'Open+Sans', ' Rubik Glitch Pop', 'Lato']; // Example fonts

    useEffect(() => {
        if (authPageStyle) {
            Object.entries(authPageStyle).forEach(([key, value]) => {
                document.documentElement.style.setProperty(`--${key}`, `${value}`);
            });
        }
        if (HomePageStyle) {
            Object.entries(HomePageStyle).forEach(([key, value]) => {
                document.documentElement.style.setProperty(`--${key}`, `${value}`);
            });
        }
        // Object.entries(themes.authPage).forEach(([key, value]) => {
        //     document.documentElement.style.setProperty(`--${key}`, `${value}`);
        // });
        // Object.entries(themes.home).forEach(([key, value]) => {
        //     document.documentElement.style.setProperty(`--${key}`, `${value}`);
        // });

    }, [themes, authPageStyle, HomePageStyle])

    return (
        <>
            <FontImporter fonts={fonts} />
            {children}
        </>
    )
}