import NoInternetConnection from 'components/providers/NoInternetConnection'
import ReactQueryProvider from 'components/providers/ReactQueryProvider'
import ToasterProvider from 'components/providers/ToasterProvider'
import ConnectionProvider from 'components/providers/ConnectionProvider'
import RoutesComponent from './routes/RoutesComponent'
import AllStylesProvider from 'components/providers/AllStylesProvider'

const App = () => {

  return (
    <NoInternetConnection>
      <ReactQueryProvider>
        <ToasterProvider>
          <ConnectionProvider>
            <AllStylesProvider>
              <RoutesComponent />
            </AllStylesProvider>
          </ConnectionProvider>
        </ToasterProvider>
      </ReactQueryProvider>
    </NoInternetConnection>
  )
}

export default App