import { NotFoundSvg } from 'assets/images/svg';
// import { useLocation, useNavigate } from 'react-router-dom';
const NotFound = ({ isUserAccessErrorMassage }) => {
    // const location = useLocation();
    // const navigate = useNavigate();

    // const slug = location.pathname.split('/').pop();
    // console.log(slug);
    // setTimeout(() => {
    //     navigate("/")
    // }, 1000);
    return (
        <div className='not_found'>

            <div id="container">
                <NotFoundSvg fill={"#FFA91E"} />
                <div className="message">
                    {isUserAccessErrorMassage}
                </div>
            </div>
        </div>
    );
};

export default NotFound;
