import { toast } from 'components/lib/npm';
import CommonMessage from 'static/commonMessage';

const AUTO_CLOSE_TIMEOUT = 5000;

class NotificationService {
  showSuccessMessage(titleOrMessage, description, duration) {
    if (!description && !titleOrMessage) {
      titleOrMessage = `${CommonMessage.Success}`;
      description = `${CommonMessage.SuccessOperation}`;
    } else if (!description) {
      // titleOrMessage = titleOrMessage
      description = ''
    }

    if (typeof duration === 'undefined') {
      duration = AUTO_CLOSE_TIMEOUT;
    }

    const toastOptions = {
      description,
      duration,
    };

    toast.success(titleOrMessage, toastOptions);
  }

  showErrorMessage(titleOrMessage, description, duration) {
    if (!description && !titleOrMessage) {
      titleOrMessage = `${CommonMessage.Error}`;
      description = `${CommonMessage.ErrorOperation}`;
    } else if (!description) {
      // titleOrMessage = titleOrMessage
      description = ''
    }

    if (typeof duration === 'undefined') {
      duration = AUTO_CLOSE_TIMEOUT;
    }

    const toastOptions = {
      description,
      duration,
    };

    toast.error(titleOrMessage, toastOptions);
  }

  showInfoMessage(titleOrMessage, description, duration) {
    if (!description && !titleOrMessage) {
      titleOrMessage = `${CommonMessage.Info}`;
      description = `${CommonMessage.InfoOperation}`;
    } else if (!description) {
      // titleOrMessage = titleOrMessage
      description = ''
    }

    if (typeof duration === 'undefined') {
      duration = AUTO_CLOSE_TIMEOUT;
    }

    const toastOptions = {
      // position: toast.POSITION.TOP_RIGHT,
      // closeButton: true,
      description,
      duration,
    };

    toast.info(titleOrMessage, toastOptions);
  }

  showWarningMessage(titleOrMessage, description, duration) {
    if (!description && !titleOrMessage) {
      titleOrMessage = `${CommonMessage.Warning}`;
      description = `${CommonMessage.WarningOperation}`;
    } else if (!description) {
      // titleOrMessage = titleOrMessage
      description = ''
    }

    if (typeof duration === 'undefined') {
      duration = AUTO_CLOSE_TIMEOUT;
    }

    const toastOptions = {
      // position: toast.POSITION.TOP_RIGHT,
      // closeButton: true,
      description,
      duration,
    };

    toast.warning(titleOrMessage, toastOptions);
  }

}
const notificationService = new NotificationService();

export default notificationService;