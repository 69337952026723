import { Cookies } from 'components/lib/npm'
// import keys from 'config/keys'
import { decryption, encryption } from 'utils/crypto';

export const EXPIRY_TIME = 1 * 24 * 60 * 60 // 1 days
export const expireTime = () => new Date(Date.now() + (EXPIRY_TIME * 1000)); // Token expiry time


const cookies = new Cookies();

class BrowserStorageService {

  // ! ----------⬇ sessionStorage Section ⬇----------

  setSession(key, value) {
    const data = value === undefined ? "" : encryption(value);
    window?.sessionStorage.setItem(key, data);
  }

  getSession(key) {
    const data = window?.sessionStorage.getItem(key);
    if (data) {
      return decryption(data);
    } else {
      return null;
    }
  }

  removeSession(key) {
    window?.sessionStorage.removeItem(key);
  }

  removeAllSessions() {
    for (const key in window?.sessionStorage) {
      if (window?.sessionStorage.hasOwnProperty(key)) {
        this.removeSession(key);
      }
    }
  }

  // ! ----------⬇ localStorage Section ⬇----------

  setLocal(key, value) {
    const data = value === undefined ? "" : encryption(value);
    window?.localStorage.setItem(key, data);
  }

  getLocal(key) {
    try {
      const data = window?.localStorage.getItem(key);
      if (data) {
        return decryption(data);
      } else {
        return null;
      }

    } catch (error) {
      return null;
    }
  }

  removeLocal(key) {
    window?.localStorage.removeItem(key);
  }

  removeAllLocals() {
    for (const key in window?.localStorage) {
      if (window?.localStorage.hasOwnProperty(key)) {
        this.removeLocal(key);
      }
    }
  }

  // ! ----------⬇ cookie Section ⬇----------

  setCookie = (key, data, expiry) => {
    if (key && data) {
      let ciphertext = encryption(data);

      cookies.set(key, ciphertext, { path: '/', expires: expiry ?? expireTime() });
    }
  };

  getCookie = (key) => {
    const data = cookies.get(key);
    let decryptedData = data ? decryption(data) : null;
    return decryptedData;
  };

  removeCookie = (key) => {
    if (key) {
      cookies.remove(key, { expires: expireTime() });
    }
  }

}

const browserStorageService = new BrowserStorageService();
export default browserStorageService;