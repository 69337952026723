const keys = {
  app: {
    name: 'Hive_Full_Atomation',
    envType: process.env.REACT_APP_CUSTOM_VARIABLE,
    apiURL: `${process.env.REACT_APP_END_POINT}`,
    socketUrl: process.env.REACT_APP_SOCKET_END,
    clientUrl: process.env.REACT_APP_CLIENT_URL,
    secretKey: process.env.REACT_APP_SECRET_KEY
  },
  port: process.env.PORT || 4000
};

export default keys