const FontImporter = ({ fonts }) => {

    const generateFontString = (fonts) => {
        return fonts.reduce((acc, el) => {
            let s = `@import url('https://fonts.googleapis.com/css2?family=${el}&display=swap');\n`;
            acc += s;
            return acc;
        }, '');
    };
    const fontString = generateFontString(fonts);

    return (
        <style>{fontString}</style>
    );
};


export default FontImporter;