

import { create } from 'components/lib/npm'
import browserStorageService from 'services/shared/browserStorage.service'


const initialState = {
  auth: browserStorageService.getCookie('_token'),
  authenticationCode: browserStorageService.getSession('loginCode'),
  publicKeyRoute: browserStorageService.getCookie('routes'),

  isScreenLoader: true,

  isScreen: 'PhoneNumberScreen',

  isPhoneNumber: '',
  isCountryCode: 'us',
  otpTimer: 0,
  userName: '',
  isUserAccessErrorMassage: 'Page not found',
  allStyle: "",
  isTimeFlag: true,

}

const useAuthenticationStore = create((set, get) => ({

  ...initialState,
  setState: (nextState) => set(() => ({ ...nextState })),
  getState: () => get(),

}))

export default useAuthenticationStore