import { memo, RouterProvider, createBrowserRouter, Route, createRoutesFromElements } from 'components/lib/react-npm';
import { privateRouteList, publicRouteList } from 'routes/data';
import PrivateRouter from './PrivateRouter';
import PublicRouter from 'routes/PublicRouter';
import NotFound from 'pages/not-found/NotFound';
import useAuthenticationStore from 'store/authentication/useAuthenticationStore';

function RoutesComponent() {

    const { isUserAccessErrorMassage } = useAuthenticationStore();
    const render = () => {

        return (
            <>

                {/* Public Route List  */}
                {publicRouteList.map(({ title, path, element: Element, restricted, ...rest }) => (
                    <Route key={path} element={<PublicRouter title={title} path={path} {...rest} />}>
                        <Route path={path} element={<Element />} />
                    </Route>
                ))}

                {/* Private Route List */}
                {privateRouteList.map(({ title, path, allowedRoles, element: Element, ...rest }) => (
                    <Route key={path} element={<PrivateRouter allowedRoles={allowedRoles} title={title} {...rest} />}>
                        <Route path={path} element={<Element />} />
                    </Route>
                ))}
                <Route path={'/*'} element={<NotFound isUserAccessErrorMassage={isUserAccessErrorMassage} />} />

            </>
        )
    }

    const router = createBrowserRouter(createRoutesFromElements(render()));

    return <RouterProvider router={router} />

}

export default memo(RoutesComponent)