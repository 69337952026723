import { memo, Navigate, Outlet, Suspense, useLocation } from "components/lib/react-npm";
import { HelmetProvider } from "components/lib/npm";
import WrapperLayout from 'components/layout/WrapperLayout'
import useAuthenticationStore from "store/authentication/useAuthenticationStore";
import Loader from "components/ui/loader/Loader";


const PrivateRouter = ({ title, allowedRoles }) => {

    const location = useLocation();
    const { auth } = useAuthenticationStore();
    const rootPath = "/";
    return auth
        ? <Wrapper title={title} />
        : <Navigate to={rootPath} state={{ from: location }} replace />
};

const Wrapper = ({ title }) => {
    return (

        <HelmetProvider context={{}}>
            <title>{title}</title>
            <WrapperLayout>
                <Suspense fallback={<PageLader />}>
                    <Outlet />
                </Suspense>
            </WrapperLayout>
        </HelmetProvider>
    )
}

const PageLader = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen login_background">
            <Loader size={50} color={'black'}/>
        </div>
    )
}
export default memo(PrivateRouter);