
import { useEffect } from "components/lib/react-npm"
import { useSocket } from "services/socket/query/useSocket"


export default function ConnectionProvider({ children }) {

  // let navigate = useNavigate();

  // const handleNavigate = (link) => {
  //   navigate(link);
  // };
  useSocket();
  useEffect(() => {

    // (async () => {
    //   try {

    //     const { data } = await axiosClient.get('/auth/Redirect/898798')

    //     console.log(data.data);

    //   } catch (error) {
    //     console.error(error.message);
    //   }

    // })()

  }, [])


  return children

}
