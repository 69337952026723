import { useEffect } from 'components/lib/react-npm';
import keys from 'config/keys';
import useSocketSlice from 'store/socket-store/useSocketSlice';
import { decryptSocketData } from 'utils/crypto';

export function useSocketEventsListener(events) {
    const { socket } = useSocketSlice();

    const socketDecryptedResponse = (res) => {
        if (keys.app.envType !== 'local_value') {
            return decryptSocketData(res);
        }
        return res
    };



    useEffect(() => {

        if (socket?.connected) {
            socket.on('connect', () => {
                events.connection()
            });
            socket.io.on('reconnect', () => events.reconnection());

            for (const event of events) {
                socket.on(event.name, (res) => event.handler(socketDecryptedResponse(res)));
            }


            return () => {
                // socket && socket.off('response');
            };
        }
    }, [socket?.connected, events, socket]);

    // Return anything you need, or leave empty if not needed
}
