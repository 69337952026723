import { closeRoundIcon } from "assets/utils/images";
import { Header } from "pages/hader/hader"
import useSocketResponseStore from "store/soket-response-store/setSocketResponseState";

function WrapperLayout({ children }) {
    const { sessionExpireManager } = useSocketResponseStore();

    return (
        <div className="main_dashboard">
            {sessionExpireManager.message && (
                <div className="relative bg-green-100 text-green-800 p-4 costume_Toter">
                    <span className="mr-2">{sessionExpireManager.message}</span>
                    <img
                        src={closeRoundIcon}
                        alt="Close"
                        className="absolute top-0 right-0 mt-4 mr-2 h-6 w-6 cursor-pointer"
                        onClick={() => document.documentElement.style.setProperty("--costume_Toter--massage", `none`)}
                    />
                </div>
            )}
            <Header />

            <main className="hive_medical_main lg:gap-6 md:gap-3 gap-5 flex flex-wrap">
                {children}
            </main>
        </div>

    )
}

export default WrapperLayout