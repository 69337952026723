import { create } from "components/lib/npm";

const initialSocketState = {
  socket: null,
}

const useSocketSlice = create((set, get) => ({
  ...initialSocketState,
  setState: (nextState) => set({ ...nextState }),
  getState: () => get()
}));

export default useSocketSlice