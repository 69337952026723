import { QueryClient, QueryClientProvider, ReactQueryDevtools } from 'components/lib/npm';
import keys from 'config/keys';

const OPTIONS = {
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 1,
        },
    },
}

const queryClient = new QueryClient(OPTIONS);

function ReactQueryProvider({ children }) {
    return (
        <QueryClientProvider client={queryClient}>
            {children}
            {keys.app.envType === 'local_value' ? <ReactQueryDevtools key={'query_devtools'} initialIsOpen={false} position='right' buttonPosition='bottom-right' /> : null}
        </QueryClientProvider>
    )
}
export default ReactQueryProvider