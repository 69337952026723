


import { lazy } from "components/lib/react-npm";
import roles from "../static/roles";
const Home = lazy(() => import("pages/home/Home"));
const CommonAuthScreens = lazy(() => import("pages/authentication/common-auth-screens/CommonAuthScreens"));
const PhpDirectAuthScreen = lazy(() => import("pages/authentication/php-direct-auth-screen/PhpDirectAuthScreen"));
const { USER } = roles;

export const privateRouteList = [
    {
        title: "Home",
        path: '/home',
        element: Home,
        allowedRoles: [USER],
    },
    // {
    //     title: "Unauthorized Page",
    //     path: '/unauthorized',
    //     element: Unauthorized,
    //     allowedRoles: [USER],
    // }
];

export const publicRouteList = [

    // {
    //     title: "Phone Number Screen",
    //     path: '/:slug?',
    //     element: PhoneNumberScreen,
    // },
    {
        title: "PHP Slug",
        path: '/php/:slug',
        element: PhpDirectAuthScreen,
    },
    {
        title: "auth screen",
        path: '/:slug',
        element: CommonAuthScreens,
    },
];

// export const otpScreePublicRouteList = ["/Login/:slug?"];
// export const loginPublicRouteList = ['/Login/:slug?'];